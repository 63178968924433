import { MenuOutlined } from '@ant-design/icons'
import { Button, Checkbox } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy, arrayMove, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import ClickableText from '@components/ClickableText'
import ButtonLink from '@components/ButtonLink'
import { observer } from 'mobx-react'

const ColumnItem = ({ value, index, toggleColumnVisible }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: `${value.name}-${index}` })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div ref={setNodeRef} style={style} className="dragging-item">
      <div
        {...attributes}
        {...listeners}
        className="dragging-item-trigger cursor-grab"
        style={{ display: 'flex', alignItems: 'stretch' }}
      >
        <MenuOutlined style={{ cursor: 'grab', color: 'white', display: 'flex', alignItems: 'center' }} />
      </div>
      <div className="dragging-item-content flex justify-between w-full">
        <span className="mr-2">{value.title}</span>
        <Checkbox checked={!value.hidden} onChange={() => toggleColumnVisible(index)} />
      </div>
    </div>
  )
}

const TableSetting = ({ columns, onChange, setDefaultColumns, onClose }) => {
  const [items, setItems] = useState(columns)
  const containerRef = useRef(null)

  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    const handleScroll = () => {
      const maxScrollTop = container.scrollHeight - container.clientHeight
      if (container.scrollTop > maxScrollTop) {
        container.scrollTop = maxScrollTop // Prevents overscrolling
      }
    }

    container.addEventListener('scroll', handleScroll)
    return () => container.removeEventListener('scroll', handleScroll)
  }, [])

  const toggleColumnVisible = columnIndex => {
    const newColumns = [...items]
    newColumns[columnIndex].hidden = !newColumns[columnIndex].hidden
    setItems(newColumns)
    onChange(newColumns)
  }

  const showHideAll = isShowAll => {
    const checkHasHidden = items.findIndex(t => (t.hidden ?? false) === isShowAll)
    if (checkHasHidden > -1) {
      const newColumns = items.map(item => ({
        ...item,
        hidden: !isShowAll,
      }))
      setItems(newColumns)
      onChange(newColumns)
    }
  }

  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 10 } }))

  const handleDragEnd = event => {
    const { active, over } = event
    if (!over) return

    const oldIndex = items.findIndex((i, idx) => `${i.name}-${idx}` === active.id)
    const newIndex = items.findIndex((i, idx) => `${i.name}-${idx}` === over.id)

    if (oldIndex !== -1 && newIndex !== -1) {
      const newItems = arrayMove(items, oldIndex, newIndex)
      setItems([...newItems])
      onChange([...newItems])
    }
  }

  const handleSetDefaultColumns = () => {
    const defaultColumns = setDefaultColumns()
    setItems(defaultColumns)
    onChange(defaultColumns)
  }

  return (
    <div className="bg-white p-2">
      <div className="flex justify-between items-center sticky top-0 bg-white z-10">
        <div className="text-xl font-medium pt-2">View Option</div>
        <ClickableText className="mt-2" onClick={onClose}>
          Close
        </ClickableText>
      </div>
      <Button color="default" className="w-full my-2 px-2" onClick={handleSetDefaultColumns}>
        Default Columns
      </Button>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext
          items={items.map((item, index) => `${item.name}-${index}`)}
          strategy={verticalListSortingStrategy}
        >
          <div className="flex justify-between gap-2">
            <p style={{ color: 'darkgray' }}>Shown</p>
            <div className="flex gap-2">
              <ButtonLink className="btn-show-all underline" onClick={() => showHideAll(true)}>
                Show All
              </ButtonLink>
              <ButtonLink className="btn-hide-all underline" onClick={() => showHideAll(false)}>
                Hide All
              </ButtonLink>
            </div>
          </div>
          <div className="max-h-[60vh] overflow-auto" ref={containerRef}>
            {items.map((value, index) => (
              <ColumnItem
                key={`${value.name}-${index}`}
                value={value}
                index={index}
                toggleColumnVisible={toggleColumnVisible}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  )
}

export default observer(TableSetting)
