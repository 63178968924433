import appStore from '@stores/appStore'
import { ApiService } from './ApiService'
import { QueryOptions } from './baseService'
import { RECRUITMENT_STATUS_ENUM, SPECIALIST_STATUS_ENUM } from '@utils/constants'

const MAX_RESULT_COUNT = 20

type OptionName =
  | 'CaseManager'
  | 'Centre'
  | 'Client'
  | 'Company'
  | 'ServiceProvider'
  | 'Specialist'
  | 'ActiveSpecialist'
  | 'Contractor'
  | 'LookupConfig'
  | 'FundingProvider'
  | 'FundingAgreement'
  | 'CheckList'
  | 'Borrower'
  | 'City'
  | 'User'
  | 'Staff'

type CacheItem = {
  name: OptionName
  lastQueryTime: string
  enabled: boolean
}
class OptionCacheEnabled {
  private cacheItems: { [key: string]: CacheItem } = {} // Cache items for each option

  constructor() {
    this.initDefaultIfNotExist('CaseManager')
    this.initDefaultIfNotExist('Centre')
    this.initDefaultIfNotExist('Client')
    this.initDefaultIfNotExist('Company')
    this.initDefaultIfNotExist('ServiceProvider')
    this.initDefaultIfNotExist('Specialist')
    this.initDefaultIfNotExist('ActiveSpecialist')
    this.initDefaultIfNotExist('Contractor')
    this.initDefaultIfNotExist('LookupConfig')
    this.initDefaultIfNotExist('FundingProvider')
    this.initDefaultIfNotExist('FundingAgreement')
    this.initDefaultIfNotExist('CheckList')
    this.initDefaultIfNotExist('Borrower')
    this.initDefaultIfNotExist('City')
    this.initDefaultIfNotExist('User')
    this.initDefaultIfNotExist('Staff')
  }
  isCacheEnable(name: string) {
    return this.cacheItems[name]?.enabled
  }

  private initDefaultIfNotExist(name: OptionName) {
    if (!this.cacheItems[name]) {
      this.cacheItems[name] = { name, lastQueryTime: '', enabled: true }
    }
  }

  disableCache(name: OptionName) {
    this.initDefaultIfNotExist(name)
    this.cacheItems[name].enabled = false
    this.cacheItems[name].lastQueryTime = new Date().toISOString()
  }

  enableCache(name: OptionName) {
    this.initDefaultIfNotExist(name)
    this.cacheItems[name].enabled = true
    this.cacheItems[name].lastQueryTime = new Date().toISOString()
  }

  autoEnableCache(name: OptionName) {
    // auto enable cache after 5 seconds by settimeout
    if (this.cacheItems[name]?.enabled === false) {
      setTimeout(() => {
        this.cacheItems[name].enabled = true
      }, 5000)
    }
  }
}

export const OptionCacheManager = new OptionCacheEnabled()

const CaseManager = ({
  filterText = '',
  companyId = undefined,
  position = undefined,
  fromModule = undefined,
  objectId = undefined,
  maxResultCount = MAX_RESULT_COUNT,
  tenantId = undefined,
  caseManagerId = undefined,
  isOnlyGetOnboardedOrOnboarding = true,
  companyIds = [],
  excludeCompanyIds = [],
  cacheEnabled = true,
} = {}) => {
  cacheEnabled = OptionCacheManager.isCacheEnable('CaseManager') === false ? false : cacheEnabled
  if (!cacheEnabled) {
    OptionCacheManager.autoEnableCache('CaseManager')
  }
  return ApiService.CaseManager.GetAll(
    {
      filterText,
      companyId,
      position,
      fromModule,
      objectId,
      maxResultCount,
      isSelectionList: true,
      isActive: true,
      sorting: 'FullName',
      tenantId,
      caseManagerId,
      isOnlyGetOnboardedOrOnboarding,
      companyIds,
      excludeCompanyIds,
      cacheEnabled,
    },
    {
      disableCache: !cacheEnabled,
    },
  )
}

const Centre = ({
  filterText = '',
  specialistId = undefined,
  fromModule = null,
  objectId = null,
  centreOwnershipType = undefined,
  maxResultCount = MAX_RESULT_COUNT,
  tenantId = undefined,
  centreId = undefined,
  getCentreHasSessionOnly = false,
  cacheEnabled = true,
} = {}) => {
  cacheEnabled = OptionCacheManager.isCacheEnable('Centre') === false ? false : cacheEnabled
  if (!cacheEnabled) {
    OptionCacheManager.autoEnableCache('Centre')
  }
  return ApiService.Centre.GetAll(
    {
      filterText,
      specialistId,
      centreOwnershipType,
      fromModule,
      objectId,
      isSelectionList: true,
      maxResultCount,
      isActive: true,
      sorting: 'Name',
      tenantId,
      centreId,
      getCentreHasSessionOnly,
      cacheEnabled,
    },
    {
      disableCache: !cacheEnabled,
    },
  )
}

const Client = ({
  companyId = undefined,
  filterText = '',
  maxResultCount = MAX_RESULT_COUNT,
  clientId = undefined,
  cacheEnabled = true,
} = {}) => {
  cacheEnabled = OptionCacheManager.isCacheEnable('Client') === false ? false : cacheEnabled
  if (!cacheEnabled) {
    OptionCacheManager.autoEnableCache('Client')
  }
  return ApiService.Client.GetAll(
    {
      filterText,
      maxResultCount,
      isSelectionList: true,
      companyId,
      isActive: true,
      sorting: 'firstName, lastName',
      clientId,
      cacheEnabled,
    },
    {
      disableCache: !cacheEnabled,
    },
  )
}

const Company = ({
  filterText = '',
  fromModule = null,
  objectId = null,
  businessType = undefined,
  maxResultCount = MAX_RESULT_COUNT,
  tenantId = undefined,
  companyId = undefined,
  searchExact = false,
  showAsBorrowerInLenders = undefined,
  cacheEnabled = true,
} = {}) => {
  cacheEnabled = OptionCacheManager.isCacheEnable('Company') === false ? false : cacheEnabled
  if (!cacheEnabled) {
    OptionCacheManager.autoEnableCache('Company')
  }
  return ApiService.Company.GetAll(
    {
      filterText,
      businessType,
      fromModule,
      objectId,
      maxResultCount,
      isActive: true,
      isSelectionList: true,
      sorting: 'Name',
      tenantId,
      companyId,
      searchExact,
      showAsBorrowerInLenders,
      cacheEnabled,
    },
    {
      disableCache: !cacheEnabled,
    },
  )
}

const City = () => {
  return ApiService.Country.GetCitiesByCountry(appStore.currentTenant.country.id)
}

const ServiceProvider = ({
  filterText = '',
  fromModule = null,
  objectId = null,
  businessType = undefined,
  maxResultCount = MAX_RESULT_COUNT,
  tenantId = undefined,
  companyId = undefined,
  cacheEnabled = true,
} = {}) => {
  return ApiService.ServiceProvider.GetAll(
    {
      filterText,
      businessType,
      fromModule,
      objectId,
      maxResultCount,
      isActive: true,
      isSelectionList: true,
      sorting: 'Name',
      tenantId,
      companyId,
    },
    {
      disableCache: !cacheEnabled,
    },
  )
}

const Specialist = ({
  filterText = '',
  specialtyId = undefined,
  fromModule = undefined,
  objectId = undefined,
  withoutNCI = undefined,
  isMedNeg = undefined,
  typeOfAssessmentsAcceptedAllow = [],
  includeServiceRates = undefined,
  includeCV = undefined,
  isSelectionList = true,
  maxResultCount = MAX_RESULT_COUNT,
  searchByUsedSpecialist = false,
  includeSpecialty = false,
  includeQualification = false,
  specialistId = undefined,
  recruitmentStatus = undefined,
  specialistStatus = undefined,
  isActive = false,
  cacheEnabled = true,
} = {}) => {
  cacheEnabled = OptionCacheManager.isCacheEnable('Specialist') === false ? false : cacheEnabled
  if (!cacheEnabled) {
    OptionCacheManager.autoEnableCache('Specialist')
  }
  if (isActive) {
    return ApiService.Specialist.GetAll(
      {
        specialistStatus: SPECIALIST_STATUS_ENUM.ACTIVE,
        recruitmentStatus: [RECRUITMENT_STATUS_ENUM.ONBOARDING, RECRUITMENT_STATUS_ENUM.ONBOARDED],
        filterText,
        specialtyId,
        fromModule,
        objectId,
        withoutNCI,
        isMedNeg,
        maxResultCount,
        isSelectionList: isSelectionList,
        sorting: 'FullName',
        typeOfAssessmentsAcceptedAllow,
        includeServiceRates,
        includeCV,
        searchByUsedSpecialist,
        includeSpecialty,
        includeQualification,
        specialistId,
        cacheEnabled,
      },
      {
        disableCache: !cacheEnabled,
      },
    )
  }

  return ApiService.Specialist.GetAll(
    {
      filterText,
      specialtyId,
      fromModule,
      objectId,
      withoutNCI,
      isMedNeg,
      maxResultCount,
      specialistStatus,
      isSelectionList: isSelectionList,
      sorting: 'FullName',
      typeOfAssessmentsAcceptedAllow,
      includeServiceRates,
      includeCV,
      searchByUsedSpecialist,
      includeSpecialty,
      includeQualification,
      specialistId,
      recruitmentStatus,
      cacheEnabled,
    },
    {
      disableCache: !cacheEnabled,
    },
  )
}

const ActiveSpecialist = ({
  filterText = '',
  specialtyId = undefined,
  fromModule = undefined,
  objectId = undefined,
  withoutNCI = undefined,
  isMedNeg = undefined,
  typeOfAssessmentsAcceptedAllow = [],
  includeServiceRates = undefined,
  includeCV = undefined,
  isSelectionList = true,
  maxResultCount = MAX_RESULT_COUNT,
  searchByUsedSpecialist = false,
  includeSpecialty = false,
  includeQualification = false,
  specialistId = undefined,
  cacheEnabled = true,
} = {}) => {
  cacheEnabled = OptionCacheManager.isCacheEnable('Specialist') === false ? false : cacheEnabled
  if (!cacheEnabled) {
    OptionCacheManager.autoEnableCache('Specialist')
  }
  return ApiService.Specialist.GetAll(
    {
      filterText,
      specialtyId,
      fromModule,
      objectId,
      withoutNCI,
      isMedNeg,
      maxResultCount,
      specialistStatus: SPECIALIST_STATUS_ENUM.ACTIVE,
      recruitmentStatus: [RECRUITMENT_STATUS_ENUM.ONBOARDING, RECRUITMENT_STATUS_ENUM.ONBOARDED],
      isSelectionList: isSelectionList,
      sorting: 'FullName',
      typeOfAssessmentsAcceptedAllow,
      includeServiceRates,
      includeCV,
      searchByUsedSpecialist,
      includeSpecialty,
      includeQualification,
      specialistId,
      cacheEnabled,
    },
    {
      disableCache: !cacheEnabled,
    },
  )
}

const Contractor = ({
  filterText = '',
  companyId = undefined,
  fromModule = undefined,
  objectId = undefined,
  type = undefined,
  maxResultCount = MAX_RESULT_COUNT,
  cacheEnabled = true,
} = {}) => {
  cacheEnabled = OptionCacheManager.isCacheEnable('Contractor') === false ? false : cacheEnabled
  if (!cacheEnabled) {
    OptionCacheManager.autoEnableCache('Contractor')
  }
  return ApiService.Contractor.GetAll(
    {
      filterText,
      companyId,
      type,
      maxResultCount,
      isActive: true,
      isSelectionList: true,
      sorting: 'FullName',
      fromModule,
      objectId,
    },
    {
      disableCache: !cacheEnabled,
    },
  )
}

const LookupConfig = ({
  type = undefined,
  parentIds = undefined,
  filterText = '',
  fromModule = undefined,
  tenantId = null,
  isActive = true,
  cacheEnabled = true,
  maxResultCount = 20,
} = {}) => {
  cacheEnabled = OptionCacheManager.isCacheEnable('LookupConfig') === false ? false : cacheEnabled
  if (!cacheEnabled) {
    OptionCacheManager.autoEnableCache('LookupConfig')
  }
  return ApiService.LookupConfig.GetAll(
    {
      filterText,
      maxResultCount: maxResultCount,
      type,
      isActive: isActive,
      parentIds,
      sorting: 'label',
      fromModule: fromModule,
      tenantId: tenantId,
      cacheEnabled,
    },
    {
      disableCache: !cacheEnabled,
    },
  )
}

const FundingProvider = ({ filterText = '', fromModule = undefined, objectId = undefined } = {}) => {
  return ApiService.FundingProvider.GetAll({
    filterText,
    maxResultCount: MAX_RESULT_COUNT,
    isActive: true,
    isSelectionList: true,
    fromModule: fromModule,
    objectId: objectId,
  })
}

const Borrower = ({ fundingProviderId }) => {
  return ApiService.FundingAgreement.getBorrowerInfo(fundingProviderId)
}

const FundingAgreement = ({
  filterText = '',
  fundingProviderId = undefined,
  borrowerId = undefined,
  maxResultCount = MAX_RESULT_COUNT,
  cacheEnabled = true,
} = {}) => {
  return ApiService.FundingAgreement.GetAll(
    {
      filterText,
      fundingProviderId,
      borrowerId,
      maxResultCount,
      isActive: true,
      isSelectionList: true,
    },
    {
      disableCache: !cacheEnabled,
    },
  )
}

const User = ({
  filterText = '',
  roleNames = undefined,
  maxResultCount = MAX_RESULT_COUNT,
  cacheEnabled = true,
} = {}) => {
  cacheEnabled = OptionCacheManager.isCacheEnable('User') === false ? false : cacheEnabled
  if (!cacheEnabled) {
    OptionCacheManager.autoEnableCache('User')
  }
  return ApiService.User.GetAll(
    {
      filterText,
      roleNames,
      maxResultCount,
      isActive: true,
      isSelectionList: true,
      sorting: 'Name',
      cacheEnabled,
    },
    {
      disableCache: !cacheEnabled,
    },
  )
}

const Staff = ({ filterText = '', isGetBSOFromCM = false, maxResultCount = MAX_RESULT_COUNT, cacheEnabled = true }) => {
  cacheEnabled = OptionCacheManager.isCacheEnable('Staff') === false ? false : cacheEnabled
  if (!cacheEnabled) {
    OptionCacheManager.autoEnableCache('Staff')
  }
  return ApiService.User.GetStaffs({
    filterText,
    maxResultCount,
    sorting: 'Name',
    isGetBSOFromCM: isGetBSOFromCM,
    cacheEnabled,
  })
}

const CheckList = ({ filterText = '', maxResultCount = 20 } = {}) => {
  return ApiService.Checklist.GetAll({ filterText, maxResultCount, sorting: 'Name', isActive: true })
}

const OptionApi = {
  User,
  Staff,
  Centre,
  CaseManager,
  Client,
  Company,
  ServiceProvider,
  Specialist,
  ActiveSpecialist,
  Contractor,
  LookupConfig,
  FundingProvider,
  FundingAgreement,
  CheckList,
  Borrower,
  City,
}

export default OptionApi
