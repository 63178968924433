const baseViewPath = '/view'

/**
 * @param {string} moduleName
 * @returns
 */
function getRouteConfig(modulePath, moduleName) {
  return {
    list: (params = {}, extraProps = {}) => {
      const qs = new URLSearchParams(params).toString()

      return {
        path: `${baseViewPath}/${modulePath}${qs ? `?` : ''}${qs}`,
        tabTitle: `${moduleName} List`,
        ...extraProps,
      }
    },
    detail: (id, title, postFix = null) => {
      return {
        path: `${baseViewPath}/${modulePath}/detail/${id}`,
        route: `${baseViewPath}/${modulePath}/detail/:id`,
        tabTitle: `${title}`,
        postFix: ` - ${postFix ? postFix : moduleName}`,
        tabName: `${moduleName}-${id}`,
      }
    },
    add: (title?: string) => {
      return {
        path: `${baseViewPath}/${modulePath}/add`,
        tabTitle: title ? title : `Create ${moduleName}`,
      }
    },
  }
}
export const ROUTE_PATH = {
  caseManager: getRouteConfig('case-managers', 'Case Manager'),
  company: getRouteConfig('companies', 'Company'),
  client: getRouteConfig('clients', 'Client'),
  centre: getRouteConfig('centres', 'Centre'),
  specialist: getRouteConfig('specialists', 'Specialist'),
  contractor: getRouteConfig('contractors', 'Contractor'),
  assessment: getRouteConfig('assessments', 'Assessment'),
  massTorts: getRouteConfig('mass-torts', 'Mass Torts'),
  ot: getRouteConfig('ot', 'OT'),
  treatment: getRouteConfig('treatment-providers', 'Medical Treatment & Providers'),
  supplementary: getRouteConfig('supplementaries', 'Supplementary'),
  fileReview: getRouteConfig('file-reviews', 'File Review'),
  transactionInvoice: getRouteConfig('transaction-invoice', 'Funding Invoice'),
  tpd: getRouteConfig('tpd', 'TPD'),
  medicalRecord: getRouteConfig('clinical-records', 'Medical Record'),
  medneg: getRouteConfig('mednegs', 'MedNeg'),
  co: getRouteConfig('co-teleconference', 'Conclave/Court Review'),
  invoice: getRouteConfig('invoices', 'Invoice'),
  fundingProvider: getRouteConfig('funding-providers', 'Funding Provider'),
  fundingRequest: getRouteConfig('facility-requests', 'Facility Request'),
  fundingAgreement: getRouteConfig('facility-agreements', 'Facility Agreement'),
  fundingTransaction: getRouteConfig('facility-transactions', 'Facility Transaction'),
  disbursementFunding: getRouteConfig('disbursement-funding', 'Funding Transaction'),
  marketingCampaign: getRouteConfig('marketing-campaigns', 'Marketing Campaign'),
  checkList: getRouteConfig('check-lists', 'CheckList'),
  ndis: getRouteConfig('ndis', 'NDIS'),
  mailTemplate: getRouteConfig('mail-templates', 'Mail Templates'),
  user: getRouteConfig('users', 'Login Users'),
  systemUpdate: getRouteConfig('system-update', 'System Update'),
  tenantInvoice: getRouteConfig('tenant-invoice', 'Tenant Invoice'),
  tenantSetting: getRouteConfig('tenants', 'System Configuration'),
  mva: getRouteConfig('mva-claim', 'MVA Claim'),
  talkingPoint: getRouteConfig('talking-point', 'Talking Point'),
  documentTypes: getRouteConfig('document-types', 'Document Types'),
  serviceProvider: getRouteConfig('service-provider', 'Service Provider'),
  timeTracking: getRouteConfig('time-tracking', 'Time Tracking'),
  videoMeeting: getRouteConfig('video-meeting', 'Video Meeting'),
}
