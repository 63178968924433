import ActionRequired from './modules/ActionRequiredService'
import PIC from './modules/PICService'
import Assessment from './modules/Assessment'
import AppointmentSearchHistoryService from './modules/AppointmentSearchHistoryService'
import AppointmentSession from './modules/AppointmentSessionService'
import Attachment from './modules/AttachmentService'
import CaseClientDetail from './modules/CaseClientDetail'
import CaseManager from './modules/CaseManagerService'
import CaseStatusLog from './modules/CaseStatusLogService'
import Centre from './modules/CentreService'
import Client from './modules/ClientService'
import Company from './modules/CompanyService'
import Contractor from './modules/ContractorService'
import Correspondence from './modules/CorrespondenceService'
import Country from './modules/CountryService'
import EmailTemplate from './modules/EmailTemplateService'
import EmailTemplatePreview from './modules/EmailTemplatePreviewService'
import HourlyRate from './modules/HourlyRateService'
import LookupConfig from './modules/LookupConfigService'
import Payment from './modules/PaymentService'
import ServiceItem from './modules/ServiceItemService'
import Specialist from './modules/SpecialistService'
import SystemConfig from './modules/SystemConfigService'
import SystemReport from './modules/SystemReportService'
import User from './modules/UserService'
import UserGroup from './modules/UserGroupService'
import VideoSession from './modules/VideoSessionService'
import AMAFeeCodeService from './modules/AMAFeeCodeService'
import SystemError from './modules/SystemErrorService'
import IMSApprovalCodes from './modules/IMSApprovalCodes'
import SaleTarget from './modules/SaleTarget'
import File from './modules/FileService'
import Profile from './modules/Profile'
import ActionRequiredBilling from './modules/ActionRequiredBilling'
import Supplementary from './modules/Supplementary'
import FileStatic from './modules/FileStaticService'
import RolesService from './modules/RolesService'
import ReportService from './modules/ReportService'
import EntityHistory from './modules/EntityHistory'
import Clinical from './modules/ClinicalService'
import MedicalRecord from './modules/ClinicalRecord'
import MailBox from './modules/MailBoxService'
import MarketingCampaign from './modules/MarketingCampaign'
import MedNeg from './modules/MedNegService'
import SearchService from './modules/SearchService'
import PMSService from './modules/PMSService'
import Tenant from './modules/TenantService'
import QuickBook from './modules/QuickBookService'
import Xero from './modules/XeroService'
import RingCentral from './modules/RingCentralService'
import UHG from './modules/UHGService'
import FileTemplate from './modules/FileTemplate'
import CaseDictationReport from './modules/CaseDictationReport'
import ClioApp from './modules/ClioApp'
import LeapApp from './modules/LeapApp'
import PEApp from './modules/PEApp'
import ActionStepApp from './modules/ActionStepApp'
import SmokeBallService from './modules/SmokeBallService'
import FundingRequest from './modules/FacilityRequest'
import FundingAgreement from './modules/FacilityAgreement'
import FundingTransaction from './modules/FacilityTransaction'
import DisbursementFunding from './modules/DisbursementFunding'
import CreditRequest from './modules/CreditRequest'
import Checklist from './modules/ChecklistService'
import FundingProvider from './modules/FundingProvider'
import TimeTracking from './modules/TimeTracking'
import NDIS from './modules/NDIS'
import EmailTracking from './modules/EmailTrackingService'
import HealthStatus from './modules/HealthStatusService'
import InsuranceClaim from './modules/InsuranceClaim'
import Language from './modules/Language'
import AttachmentType from './modules/AttachmentType'
import SystemUpdate from './modules/SystemUpdate'
import SystemInvoice from './modules/SystemInvoice'
import TimeBreakDown from './modules/TimeBreakDown'
import Edition from './modules/Edition'
import Migration from './modules/Migration'
import MvaService from './modules/MvaService'
import Paperwork from './modules/Paperwork'
import Cancellation from './modules/Cancellation'
import MagConnect from './modules/MagConnect'
import TalkingPoint from './modules/TalkingPointService'
import TalkingPointCategory from './modules/TalkingPointCategoryService'
import TalkingPointState from './modules/TalkingPointStateService'
import CasePermission from './modules/CasePermissionService'
import OnetimeAccess from './modules/OnetimeAccess'
import ServiceProvider from './modules/ServiceProviderService'
import NDISServiceItem from './modules/NDISServiceItemService'
import VideoMeeting from './modules/VideoMeetingService'
import CaseNote from './modules/CaseNote'
import RepaymentInvoice from './modules/RepaymentInvoice'
import BackgroundJob from './modules/BackgroundJob'
import Holiday from './modules/HolidaysService'
import AdHocTemplate from './modules/AdHocTemplate'
import Notification from './modules/NotificationService'
import Tpd from './modules/TpdService'
import DocumentType from './modules/DocumentTypes'

export const ApiService = {
  OnetimeAccess,
  ActionRequired,
  PIC,
  MagConnect,
  Assessment,
  AppointmentSession,
  AppointmentSearchHistoryService,
  Attachment,
  CaseClientDetail,
  CaseManager,
  CaseNote,
  CaseStatusLog,
  Centre,
  Client,
  Company,
  ServiceProvider,
  Contractor,
  Correspondence,
  Country,
  EmailTemplate,
  EmailTemplatePreview,
  HourlyRate,
  LookupConfig,
  Payment,
  ServiceItem,
  Specialist,
  SystemConfig,
  User,
  UserGroup,
  VideoSession,
  AMAFeeCodeService,
  IMSApprovalCodes,
  SaleTarget,
  File,
  Profile,
  ActionRequiredBilling,
  RolesService,
  ReportService,
  EntityHistory,
  Supplementary,
  Clinical,
  MedicalRecord,
  FileStatic,
  MailBox,
  MarketingCampaign,
  SearchService,
  MedNeg,
  Tenant,
  QuickBook,
  Xero,
  RingCentral,
  UHG,
  FileTemplate,
  CaseDictationReport,
  ClioApp,
  LeapApp,
  PEApp,
  ActionStepApp,
  FundingRequest,
  FundingAgreement,
  SmokeBallService,
  FundingTransaction,
  DisbursementFunding,
  CreditRequest,
  Checklist,
  FundingProvider,
  TimeTracking,
  NDIS,
  EmailTracking,
  HealthStatus,
  InsuranceClaim,
  Language,
  AttachmentType,
  SystemReport,
  SystemUpdate,
  SystemInvoice,
  TimeBreakDown,
  Edition,
  Migration,
  PMSService,
  MvaService,
  Paperwork,
  Cancellation,
  SystemError,
  DocumentType,
  TalkingPoint,
  TalkingPointCategory,
  TalkingPointState,
  CasePermission,
  NDISServiceItem,
  VideoMeeting,
  RepaymentInvoice,
  BackgroundJob,
  Holiday,
  AdHocTemplate,
  Notification,
  Tpd,
}
